.container {
    padding: 12px 8px;
}

.container_body {
    margin: 0 auto;
    position: relative;
    max-width: 960px;
}

.container_theme_dark {
    background-color: var(--color-text);
    color: #f1f1f1;
}

.container_theme_grey {
    background-color: #f1f1f1;
    color: var(--color-text);
}

.navbar .logo {
    height: 28px;
}

.navbar .logo img {
    height: 28px;
}

.navbar .container_body {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav a {
    flex: 1;
    color: var(--color-text);
    padding: 0 10px;
    text-decoration: none;
}

nav a:hover,
nav a.active {
    text-decoration: underline;
}

footer {
    margin-top: auto;
    width: 100%;
    height: 68px;
    text-align: center;
}

.copyright {
    font-size: 14px;
    margin: 10px 0 0 0;
    color: #777777;
}