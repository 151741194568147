.container.loading h1 {
    height: 37px;
    width: 70%;
}

.container.loading h3 {
    height: 22px;
    width: 50%;
}

.container.loading p {
    height: 18px;
    width: 100%;
}

.container.loading h1,
.container.loading h3,
.container.loading p {
    background: rgba(68, 68, 68, 0.3);
    border-radius: 50px;
}

.container.container_theme_dark.loading h1,
.container.container_theme_dark.loading p {
    background: rgba(241, 241, 241, 0.3);
}

.container.loading .articles_link {
    margin-top: 30px;
}

.container.loading .articles_link h3 {
    margin: 0 0 5px;
}

.container.loading .articles_link p {
    margin: 0;
}
